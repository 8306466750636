<template>
  <div class="challenge-wrapper">
    <dialog-message></dialog-message>
    <div class="columns is-mobile">
      <div class="column">
        <input class="input" type="text" v-model="text" placeholder="Your answer">
      </div>
    </div>
    <div class="button is-primary" @click="checkAnswer">{{ check_answer_button_text }}</div>
  </div>
</template>

<script>
import Rails from '@rails/ujs';
import DialogMessage from '../utility/DialogMessage';

export default {
  props: ['answer', 'check_answer_button_text', 'check_again_button_text', 'check_attempt_path'],

  components: {
    DialogMessage
  },

  data() {
    return {
      text: ''
    }
  },

  methods: {
    checkAnswer() {
      let answerDistances = []
      this.answer.forEach((answer_string) => {
         answerDistances.push(this.levenshteinSimilarity(answer_string, this.text));
      })

      console.log(answerDistances);
      // TODO: Refactor to make it DRY
      if (answerDistances.some(el => el > 0.8)) {
        this.status = 'correct'

        Rails.ajax({
          type: "PATCH",
          url: this.check_attempt_path,
          dataType: 'json',
          data: "digital_correct=true",
          success: function (resp) {
            console.log(resp)
          },
          error: function (resp) {
            console.log(resp)
          }
        })

      } else {
        this.status = 'incorrect'
        this.$buefy.dialog.alert(this.check_again_button_text)
      }
    },

    levenshteinSimilarity(s1, s2) {
      let longer = s1;
      let shorter = s2;
      if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
      }
      let longerLength = longer.length;
      if (longerLength == 0) {
        return 1.0;
      }
      return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    },

    editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      let costs = new Array();
      for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
          if (i == 0)
            costs[j] = j;
          else {
            if (j > 0) {
              let newValue = costs[j - 1];
              if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                    costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0)
          costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    }
  }
}
</script>

