// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import TurbolinksAdapter from 'vue-turbolinks'
const images = require.context('../images', true)
import Vue from 'vue/dist/vue.esm'
import Buefy from 'buefy'
import "trix"
import "@rails/actiontext"

import FlashMessage from "./components/utility/FlashMessage";
import TwoTables from "./components/digital_questions/TwoTables"
import TextInput from "./components/digital_questions/TextInput"
import Sequence from "./components/digital_questions/Sequence"
import Confetti from "./components/Confetti"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

Vue.use(Buefy)
Vue.use(TurbolinksAdapter)
Vue.config.ignoredElements = ['trix-editor', 'action-text-attachment'] // otherwise vue tries to init the trix-editor of actiontext

Vue.component('FlashMessage', FlashMessage)
Vue.component('TwoTables', TwoTables)
Vue.component('TextInput', TextInput)
Vue.component('Sequence', Sequence)
Vue.component('Confetti', Confetti)

import "./styles/application.scss"

document.addEventListener('turbolinks:load', () => {
    const app = new Vue({
        el: '[data-behavior="vue"]',
        data: {
            showNav: false
        }
    })
})
