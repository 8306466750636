<template>
  <div class="challenge-wrapper">
    <div class="columns is-mobile">
      <div class="challenge-twoTables-column">
        <div class="challenge-table">
          <h3 class="is-size-4">{{correct_text}}:</h3>
          <draggable class="challenge-tableInner" :list="mappedTableAShuffled" group="people">
            <div
                class="challenge-tableItem"
                v-for="(element, index) in mappedTableAShuffled"
                :key="index"
            >
              {{ element.name }}
            </div>
          </draggable>
        </div>
      </div>

      <div class="challenge-twoTables-column">
        <div class="challenge-table">
          <h3 class="is-size-4">{{incorrect_text}}:</h3>
          <draggable class="challenge-tableInner" :list="mappedTableBShuffled" group="people">
            <div
                class="challenge-tableItem"
                v-for="(element, index) in mappedTableBShuffled"
                :key="index"
            >
              {{ element.name }}
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="button is-primary" @click="checkAnswer">{{ check_answer_button_text }}</div>
  </div>
</template>

<script>
import {shuffle, isEqual} from 'lodash';
import draggable from "vuedraggable";
import Rails from "@rails/ujs";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,

  components: {
    draggable
  },

  props: [
      'table_a',
      'table_b',
      'check_answer_button_text',
      'correct_text',
      'incorrect_text',
      'check_again_button_text',
      'check_attempt_path'
  ],

  data() {
    return {
      status: 'incorrect',
      mappedTableACorrect: [],
      mappedTableBCorrect: [],
      mappedTableAShuffled: [],
      mappedTableBShuffled: [],
    };
  },

  mounted() {
    this.table_a.forEach((e, i) => this.mappedTableACorrect.push({name: e, id: i}));
    this.table_b.forEach((e, i) => this.mappedTableBCorrect.push({name: e, id: i}));

    this.mappedTableAShuffled = shuffle([...this.mappedTableACorrect, ...this.mappedTableBCorrect]);
  },

  methods: {
    checkAnswer() {
      let answersMatch = isEqual(
          _.sortBy(this.mappedTableAShuffled, [e => e.name]),
          _.sortBy(this.mappedTableACorrect, [e => e.name])
      )
      // TODO: Refactor to make it DRY
      if (answersMatch) {
        this.status = 'correct'

        Rails.ajax({
          type: "PATCH",
          url: this.check_attempt_path,
          dataType:'json',
          data: "digital_correct=true",
          success: function(resp){
            // TODO: remove
            console.log(resp)
          },
          error: function(resp){console.log(resp)}
        })
      } else {
        this.status = 'incorrect'
        this.$buefy.dialog.alert(this.check_again_button_text)
      }
    }
  }
};
</script>

