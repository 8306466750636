<template>
  <div class="challenge-wrapper">
    <div class="column is-full">
      <div class="challenge-table">
        <draggable
            v-model="mappedTableShuffled"
            group="orderChallenge"
            @start="drag = true"
            @end="drag = false"
        >
          <div
              class="challenge-tableItem"
              v-for="element in mappedTableShuffled"
              :key="element.id"
          >
            {{ element.name }}
          </div>
        </draggable>
      </div>
    </div>
    <div class="button is-primary" @click="checkAnswer">{{ check_answer_button_text }}</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {isEqual, shuffle} from "lodash";
import Rails from "@rails/ujs";

export default {
  props: ['positions', 'check_answer_button_text', 'check_again_button_text', 'check_attempt_path'],

  data() {
    return {
      status: 'incorrect',
      mappedTableCorrect: [],
      mappedTableShuffled: [],
    }
  },

  components: {
    draggable
  },

  mounted() {
    this.positions.forEach((e, i) => this.mappedTableCorrect.push({name: e, id: i}));
    this.mappedTableShuffled = shuffle([...this.mappedTableCorrect]);
  },

  methods: {
    checkAnswer() {
      let mappedIds = this.mappedTableCorrect.map((e) => e.id);
      let shuffledIds = this.mappedTableShuffled.map((e) => e.id);

      let answersMatch = isEqual(mappedIds, shuffledIds);

      // TODO: Refactor to make it DRY
      if (answersMatch) {
        this.status = 'correct'

        Rails.ajax({
          type: "PATCH",
          url: this.check_attempt_path,
          dataType:'json',
          data: "digital_correct=true",
          success: function(resp){
            // TODO: remove
            console.log(resp)
          },
          error: function(resp){console.log(resp)}
        })
      } else {
        this.status = 'incorrect'
        this.$buefy.dialog.alert(this.check_again_button_text)
      }
    }
  }
}
</script>
