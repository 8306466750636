<template>
  <div class="notification-wrapper" v-show="showNotification">
    <div class="notification is-warning">
      <button class="delete" v-on:click="showNotification = false"></button>
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'message'],
  data: function(){
    return {
      showNotification: true
    }
  }
}
</script>
