<template></template>

<script>
import {delay} from "lodash";
import confetti from '../lib/confetti.js'

export default {
  props: ['timer'],

  mounted() {
    confetti.start()
    delay(confetti.stop, this.timer)
  }
}
</script>
